import React, { useEffect } from 'react'
import { Link } from '@hub/link'
import { Text } from '@hub/text'
import { H1 } from '@hub/heading'
import Modules from '../../components/modules'
import PageLanding from '../../components/page-landing-v2'
import analyticsUtils from '../../helpers/analytics-utils'
import { createProductClickEvent } from '../../helpers/analytics-utils/productClick'
import { useAnalytics } from '../../analytics'
import {
  MetaData as ContentPageMetaData,
  NavigationGroup,
  NavigationItem,
  PromotionFeature,
  isPromotionFeature,
  ComparisonModule,
  ContentPageDisplayModes,
  isDisplayMode,
} from '@scentregroup/shared/types/content-page'
import { StandardButton } from '@scentregroup/shared/hub-components/standard-button'

interface PromotionalPageProps {
  modules?: Record<string, unknown>[]
  metaData?: ContentPageMetaData
  navigationMenu?:
    | (NavigationItem | NavigationGroup)[]
    | {
        nav: (NavigationItem | NavigationGroup)[]
      }
  loading?: boolean
  centres?: any
  nationalNavLinks?: any
}

const getProductDetailEvent = (modules: ComparisonModule): void => {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.PRODUCT_IMPRESSION,
    ecommerce: {
      currencyCode: 'AUD',
      impressions: modules
        ? modules.entries.map((item, index) => ({
            name: item.productName,
            id: `card100${index + 1}`,
            category: item.productName,
            brand: 'westfield',
            position: index + 1,
            list: item.productName,
          }))
        : [],
    },
  })
}

const PromotionalPage: React.FunctionComponent<
  React.PropsWithChildren<PromotionalPageProps>
> = ({ modules, metaData }): JSX.Element => {
  const comparisonModule = modules?.find(({ displayMode }) =>
    isDisplayMode(
      displayMode as ContentPageDisplayModes,
      ContentPageDisplayModes.COMPARISON_ITEM
    )
  ) as undefined | ComparisonModule

  useEffect(() => {
    if (comparisonModule) {
      getProductDetailEvent(comparisonModule)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const firstModule =
    modules &&
    Boolean(modules?.length) &&
    isPromotionFeature(modules[0]) &&
    (modules[0] as PromotionFeature)

  const restModules = modules && firstModule ? modules.slice(1) : modules || []

  const dgcComparisonItem =
    comparisonModule &&
    comparisonModule.entries.find(
      ({ productName }) =>
        productName === analyticsUtils.categories.DIGITAL_GIFT_CARD
    )

  const analytics = useAnalytics()
  const ctaOnClick = (): void => {
    analytics.trackEvent({
      type: 'legacy',
      data: {
        event: analyticsUtils.events.USER_CLICK,
        eventCategory: metaData?.title,
        eventLabel: (firstModule || {}).callToAction?.category,
        eventAction: analyticsUtils.categories.FEATURE_CTA,
      },
    })
  }

  return (
    <div>
      {/* explicitly sending undefined props for ts vs js reasons */}
      {modules && (
        <>
          {firstModule && (
            <PageLanding
              desktopImage={{ src: firstModule.desktopImageUrl }}
              mobileImage={{ src: firstModule.mobileImageUrl }}
              logo={undefined}
              heading={undefined}
              dataTestId={undefined}
              sx={{ maxWidth: 'size-max-fixed' }}
            >
              <div className="mx-spacing-xs hub-sm:mx-spacing-sm hub-md:mx-spacing-md p-spacing-md md:p-spacing-xl -mt-spacing-lg md:-mt-size-8 bg-background-tertiary md:w-[704px]">
                <H1>{firstModule.title}</H1>
                <Text className="mt-spacing-sm">{firstModule.body}</Text>
                <div className="gap-spacing-md mt-spacing-md flex flex-col md:flex-row md:flex-row-reverse">
                  <div className="flex-1">
                    <StandardButton
                      href={firstModule.callToAction.address}
                      as={Link}
                      onClick={ctaOnClick}
                      variant="outline"
                      sx={{ width: '100%' }}
                    >
                      {firstModule.callToAction.category}
                    </StandardButton>
                  </div>
                  <div className="flex-1">
                    {dgcComparisonItem ? (
                      <StandardButton
                        href={dgcComparisonItem.callToAction.url}
                        as={Link}
                        variant="solid"
                        onClick={() =>
                          createProductClickEvent(
                            dgcComparisonItem.productName,
                            analyticsUtils.categories.FEATURE_CTA
                          )
                        }
                        sx={{ width: '100%' }}
                      >
                        {dgcComparisonItem.callToAction.category}
                      </StandardButton>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </PageLanding>
          )}

          <Modules modules={restModules} />
        </>
      )}
    </div>
  )
}

export default PromotionalPage
