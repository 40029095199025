import React from 'react'
import { HubStyleObject } from '@hub/design-system-base'
import { Box } from '@hub/box'
import classNames from 'classnames'
import { H2 } from '@hub/heading'
import { Container } from '@hub/container'
import {
  CloudinaryImage,
  IMAGE_SIZES_FULL_WIDTH,
} from '../../hub-components/cloudinary-image'
import Image from '../image'
import { CustomThemeContext } from '../../context'
import Trackable from '../trackable'
import { trackingActions, trackingTypes } from '../../constants'
import style from './index.module.scss'

interface PageLandingProps {
  desktopImage?: {
    alt?: string | null
    src?: string | null
  } | null
  mobileImage?: {
    alt?: string | null
    src?: string | null
  } | null
  heading?: string
  logo?: string
  children: React.ReactNode
  dataTestId?: string
  imageDataTestId?: string
  analyticsMetadata?: Record<string, unknown>
  sx?: HubStyleObject
}

const PageLanding: React.FunctionComponent<
  React.PropsWithChildren<PageLandingProps>
> = ({
  desktopImage,
  mobileImage,
  heading,
  logo,
  children,
  dataTestId,
  analyticsMetadata,
  sx = {},
}) => (
  <CustomThemeContext.Consumer>
    {themeOption => (
      <Trackable
        trackingAction={trackingActions.viewPromotion}
        type={trackingTypes.promotion}
        {...analyticsMetadata}
      >
        <Box className={style.pageLandingWrapper} sx={sx}>
          {heading && (
            <Container
              maxWidth="size-max-fixed"
              gutter={[
                'spacing-mobile-margin',
                'spacing-lg',
                null,
                'spacing-none',
              ]}
              sx={{
                paddingY: ['spacing-lg', null, null, 'spacing-none'],
                margin: 'auto',
                marginBottom: 'spacing-md',
              }}
            >
              <H2
                sx={{
                  fontSize: ['font-2xl', null, 'font-4xl'],
                }}
                data-test-id={dataTestId}
              >
                {heading}
              </H2>
            </Container>
          )}
          <div
            className={classNames(style.pagelanding, {
              [style.withLogo]: Boolean(logo),
              [style.withHeading]: Boolean(heading),
              [style.Grey]: themeOption.luxury,
            })}
          >
            <div className={style.featureWrapper}>
              {Boolean(desktopImage?.src || mobileImage?.src) && (
                <div className={style.feature}>
                  <CloudinaryImage
                    imageSetOrImage={{
                      url: desktopImage?.src || mobileImage?.src || '',
                      type: 'WIDE',
                      alt: desktopImage?.alt || mobileImage?.alt || '',
                    }}
                    sizes={IMAGE_SIZES_FULL_WIDTH}
                    loadingMode="eager"
                  />
                </div>
              )}
              {logo && (
                <Image
                  className={style.logo}
                  imageUrl={logo}
                  ratio="square"
                  bgSize="contain"
                  alt={heading}
                />
              )}
              <Container
                maxWidth="size-max-fixed"
                gutter="spacing-none"
                sx={{
                  display: ['block', null, null, 'flex'],
                  transform: ['none', null, null, 'auto'],
                  alignItems: 'flex-start',
                  marginX: 'auto',
                  position: 'relative',
                  width: 'size-full',
                }}
              >
                {children}
              </Container>
            </div>
          </div>
        </Box>
      </Trackable>
    )}
  </CustomThemeContext.Consumer>
)

PageLanding.defaultProps = {
  desktopImage: {
    alt: null,
    src: null,
  },
  mobileImage: {
    alt: null,
    src: null,
  },
}

export default PageLanding
